<template>
  <index2></index2>
</template>

<script>
import Index2 from './Index2.vue'
import qz from '@/utils/mixins/qz-tray'

export default {
  mixins:[qz],
  components: { Index2 },
  mounted(){
    if(localStorage.getItem('labelPrinter')||null) this.qzSetPrinter(localStorage.getItem('labelPrinter'))
  }
}
</script>

<style>

</style>